import './../../scss/blocks/_news.scss'

import oneLineMobileSlider from './../components/oneLineMobileSlider'

document.addEventListener('DOMContentLoaded', () => {
    const options = {
        loop: false,
        autoHeight: false,
        spaceBetween: 24,
        slidesPerView: 'auto',
        initialSlide: 0,
    }

    oneLineMobileSlider('.news__slider', '93.125rem', options)
})
